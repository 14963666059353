<script lang="ts">
export default {
  name: 'HomePage',
  inheritAttrs: false,
  customOptions: {},
}
</script>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Button from '@/components/Button.vue'
// eslint-disable-next-line import/no-duplicates, @typescript-eslint/ban-ts-comment
// @ts-ignore
import srcSetWebp from '@/assets/background.jpg?w=500;900;1200;1600&format=webp&as=srcset'
// eslint-disable-next-line import/no-duplicates, @typescript-eslint/ban-ts-comment
// @ts-ignore
import srcSetAvif from '@/assets/background.jpg?w=500;900;1200;1600&format=avif&as=srcset'
// eslint-disable-next-line import/no-duplicates, @typescript-eslint/ban-ts-comment
// @ts-ignore
import srcJpeg from '@/assets/background.jpg?w=1600&format=jpg&quality=70'

const {t, locale} = useI18n({
  inheritLocale: true,
  useScope: 'local'
})
console.log('locale',locale.value)
</script>

<template>
  <div :class="$style.root">
    <picture>
      <source
        :srcset="srcSetWebp"
        type="image/webp"
        lazy
      >
      <source
        :srcset="srcSetAvif"
        type="image/avif"
        lazy
      >
      <img
        :src="srcJpeg"
        lazy
      >
    </picture>
    <router-link to="/search">
      <Button>
        {{ t('Найти свое событие') }}
      </Button>
    </router-link>
  </div>
</template>

<style module>
.root {
  margin: 0 1em;
  font-weight: bold;
}
.background {
  width: 100%;
  height: fit-content;
  position: relative;
  z-index: 1;
}

.background img {
  position: relative;
  z-index: -1;
  width: 100%;
  height: fit-content;
}
</style>

<i18n lang="json">
{
  "ru": {
    "Найти свое событие": "Найти свое событие"
  },
  "en": {
    "Найти свое событие": "Find your event"
  }
}
</i18n>
